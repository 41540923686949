import React from 'react'
import { CrossIcon, Grid, Image, P, TickIcon, Wrapper } from '@farewill/ui'
import styled from 'styled-components'
import { BORDER, BREAKPOINT, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import { formatPriceInPounds } from 'lib/formatting/pricing'
import BreakpointSwitch from 'components/BreakpointSwitch'
import Dropdown from 'components/Dropdown'
import PreventOrphan from 'components/PreventOrphan'

import { LABELS, LABELS_KEYS } from './data'

const PADDING_OFFSET = 0
const BACKGROUND_BORDER_RADIUS = 16

export type SingleProviderProps = {
  highlighted?: boolean
  logotype: string
  name: string
  price: number
  plan: { name: string; minimumWordsLineTwo: number }
  features: Partial<Record<keyof typeof LABELS_KEYS, boolean | string>>
  source: string
  index?: number
}

const StyledWrapper = styled(Grid.Item)<{
  $highlighted: boolean
  $index: number
}>`
  @media screen and (min-width: ${BREAKPOINT.M}px) and (max-width: ${BREAKPOINT.L}px) {
    ${({ $highlighted, $index }) =>
      $highlighted
        ? `
    grid-row: 1 / 4;
    grid-column: 1 / 8;

  `
        : `
    grid-row: ${$index}  / ${$index + 1} ;
    grid-column: 8 / 13;
  `}
  }
`

const StyledPromotedProvider = styled(Grid.Item)<{ $highlighted: boolean }>`
  height: ${32 - PADDING_OFFSET}px;

  ${({ $highlighted }) =>
    $highlighted &&
    `
  background: ${COLOR.ACCENT.SECONDARY};
  border-top-left-radius: ${BACKGROUND_BORDER_RADIUS}px;
  border-top-right-radius: ${BACKGROUND_BORDER_RADIUS}px;
  ${smallCaps}
  color: ${COLOR.WHITE};
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.BOLD};
  justify-content: center;
  display: flex;
  align-items: center;
  height: 32px;
  `}

  ${({ $highlighted }) =>
    !$highlighted &&
    screenMax.l`
      display: none;
  `}
`

const StyledSingleProvider = styled(Grid)<{ $highlighted: boolean }>`
  ${screenMin.xl`
    padding-bottom: ${GTR.XL};
  `}
  column-gap: 0;
  box-shadow: ${BORDER.SHADOW.M};
  border-bottom-left-radius: ${BACKGROUND_BORDER_RADIUS}px;
  border-bottom-right-radius: ${BACKGROUND_BORDER_RADIUS}px;
  padding-bottom: ${GTR.L};

  ${screenMax.l`
  padding-bottom: ${GTR.L};
  `}

  ${({ $highlighted }) =>
    !$highlighted &&
    `
    border-radius: ${BACKGROUND_BORDER_RADIUS}px;
    padding-top: ${PADDING_OFFSET}px;
  `}

  ${screenMax.l`
    padding-left: ${GTR.M};
    padding-right: ${GTR.M};
  `}
`

const StyledProviderFeatures = styled(Grid)`
  column-gap: 0;
`

const StyledLogotype = styled(Image)`
  height: 60px;
  max-height: 60px;
  max-width: 100%;
  object-fit: contain;
`

const StyledLogotypeWrapper = styled(Grid.Item)<{ $highlighted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${screenMax.l`
    margin-left: -${GTR.M};
    margin-right: -${GTR.M};
    padding-bottom: ${GTR.M};
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  `}

  @media screen and (min-width: ${BREAKPOINT.M}px) and (max-width: ${BREAKPOINT.L}px) {
    ${({ $highlighted }) =>
      !$highlighted &&
      `
      padding-top: 32px;
      padding-bottom: 32px;
    `}
  }
`

const MobileLabel = styled(Wrapper)`
  display: none;
  ${screenMax.l`
  display: inline-block;
  `}
`

const StyledFeatureWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  gap: ${GTR.S};

  ${screenMin.xl`
    justify-content: center;
  `}
`

const PromotedProvider = ({
  highlighted,
}: {
  highlighted: SingleProviderProps['highlighted']
}) => (
  <StyledPromotedProvider $highlighted={highlighted}>
    <span>{highlighted && 'Best Value'}</span>
  </StyledPromotedProvider>
)

const ProviderLogotype = ({
  logotype,
  name,
  highlighted,
}: Pick<SingleProviderProps, 'logotype' | 'name' | 'highlighted'>) => (
  <StyledLogotypeWrapper
    centered
    padding={['S', 'M', 0]}
    $highlighted={highlighted}
  >
    <StyledLogotype path={logotype} width={200} alt={name} />
  </StyledLogotypeWrapper>
)

const ProviderPlan = ({ plan }: Pick<SingleProviderProps, 'plan'>) => (
  <Grid.Item>
    <P centeredFromXL paddingFromXL={[0, 12]}>
      <PreventOrphan minimumWords={plan.minimumWordsLineTwo}>
        {plan.name}
      </PreventOrphan>
    </P>
  </Grid.Item>
)

const ProviderCost = ({ price }: Pick<SingleProviderProps, 'price'>) => (
  <Grid.Item>
    <P size="L" color={COLOR.BLACK} strong centeredFromXL>
      {formatPriceInPounds(price)}
    </P>
  </Grid.Item>
)

const ProviderFeature = ({
  feature,
  label,
}: {
  feature?: string | boolean
  label: string
}) => (
  <Grid.Item>
    {typeof feature === 'boolean' ? (
      <StyledFeatureWrapper centeredFromXL>
        {feature ? (
          <BreakpointSwitch
            breakAt={BREAKPOINT.L}
            belowComponent={
              <TickIcon background={COLOR.STATE.SUCCESS_20} size="S" />
            }
            aboveComponent={
              <TickIcon background={COLOR.STATE.SUCCESS_20} size="M" />
            }
          />
        ) : (
          <BreakpointSwitch
            breakAt={BREAKPOINT.L}
            belowComponent={
              <CrossIcon background={COLOR.BACKGROUND.SMOKE} size="S" />
            }
            aboveComponent={
              <CrossIcon background={COLOR.BACKGROUND.SMOKE} size="M" />
            }
          />
        )}
        <MobileLabel>{label}</MobileLabel>
      </StyledFeatureWrapper>
    ) : (
      <BreakpointSwitch
        breakAt={BREAKPOINT.L}
        aboveComponent={
          <Wrapper centeredFromXL padding={[5, 0, 'XXS', 0]}>
            {feature}
          </Wrapper>
        }
        belowComponent={
          <StyledFeatureWrapper centeredFromXL>
            <TickIcon background={COLOR.BACKGROUND.SMOKE} size="S" />
            <MobileLabel>{label}</MobileLabel>
          </StyledFeatureWrapper>
        }
      />
    )}
  </Grid.Item>
)

const ProviderSource = ({
  source,
}: {
  source: SingleProviderProps['source']
}) => (
  <Grid.Item centeredFromXL>
    <P size="S" padding={['XS', 0]}>
      {source}
    </P>
  </Grid.Item>
)

const ProviderFeatures = ({
  features,
  source,
}: Pick<SingleProviderProps, 'features' | 'source'>) => (
  <Grid.Item>
    <StyledProviderFeatures padding={[0, 0, 'M']} paddingFromXL={0}>
      <ProviderFeature
        feature={features.directCremation}
        label={LABELS.directCremation}
      />
      <ProviderFeature feature={features.coffin} label={LABELS.coffin} />
      <ProviderFeature feature={features.urn} label={LABELS.urn} />
      <ProviderFeature
        feature={features.handDeliveryOfAshes}
        label={LABELS.handDeliveryOfAshes}
      />
      <ProviderFeature
        feature={features.doctorsFeeCovered}
        label={LABELS.doctorsFeeCovered}
      />
      <ProviderFeature
        feature={features.urgentCollection}
        label={LABELS.urgentCollection}
      />

      <ProviderFeature
        feature={features.removalOfMedicalDevices}
        label={LABELS.removalOfMedicalDevices}
      />
      <ProviderSource source={source} />
    </StyledProviderFeatures>
  </Grid.Item>
)

const SingleProvider = ({
  highlighted,
  logotype,
  name,
  plan,
  price,
  features,
  source,
  index,
}: SingleProviderProps) => (
  <StyledWrapper
    span={12}
    spanFromL={6}
    spanFromXL={3}
    $highlighted={highlighted}
    $index={index}
  >
    <PromotedProvider highlighted={highlighted} />
    <StyledSingleProvider $highlighted={highlighted} background={COLOR.WHITE}>
      <ProviderLogotype
        highlighted={highlighted}
        logotype={logotype}
        name={name}
      />
      <ProviderPlan plan={plan} />
      <ProviderCost price={price} />
      <BreakpointSwitch
        breakAt={BREAKPOINT.L}
        belowComponent={
          <Grid.Item>
            <Dropdown
              title="See what’s included"
              openTitle="Hide what’s included"
              titlePosition="bottom"
              defaultOpen={highlighted}
            >
              <ProviderFeatures features={features} source={source} />
            </Dropdown>
          </Grid.Item>
        }
        aboveComponent={
          <ProviderFeatures features={features} source={source} />
        }
      />
    </StyledSingleProvider>
  </StyledWrapper>
)

export default SingleProvider
